<template>
  <div class="form-elements">
    <div class="row">
      <div class="flex xs12">
        <vac-card :title="$t('Add new user')">
          <form @submit.prevent="onSubmit">

            <div class="row">
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="username"
                  class="input--bg"
                  placeholder="Login"
                />
              </div>
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="firstname"
                  class="input--bg"
                  placeholder="Name"
                />
              </div>
            </div>

            <div class="row">
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="lastname"
                  class="input--bg"
                  placeholder="Last name"
                />
              </div>
              <div class="flex md6 sm6 xs12">
                <va-input
                  v-model="email"
                  class="input--bg"
                  placeholder="E-mail"
                />
              </div>
            </div>

            <va-button :style="{marginTop: '64px'}"
                       class="btn--wide"
                       type="submit">
              Save
            </va-button>
          </form>
          <va-progress-bar v-if="loading"
                           :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"
                           indeterminate></va-progress-bar>
        </vac-card>
      </div>
    </div>
  </div>
</template>

<script>
import VaButton from 'vuestic-ui/src/components/vuestic-components/va-button/VaButton';
import {sortArrayOfObject} from "../../services/Helpers/HelperObject";
import HelperUser from "../../services/Helpers/HelperUser";
import {showToastError, showToastSuccess} from "../../services/Helpers/HelperToast";

export default {
  name: 'usersAdd',
  components: {VaButton},
  created() {
  },
  data() {
    return {
      username: '',
      firstname: '',
      lastname: '',
      email: '',
      loading: false,
    };
  },
  methods: {
    collectColumns(data) {
      const length = Math.ceil(data.length / 3);
      let output = [];
      for (let index = 0; index < 3; index++) {
        const multiplier = index * length;
        const innerArray = data.slice(multiplier, multiplier + length);
        output.push(innerArray);
      }
      return output;
    },

    onSubmit() {
      this.loading = true;

      const data = {
        user: {
          username: this.username.trim(),
          firstname: this.firstname.trim(),
          lastname: this.lastname.trim(),
          email: this.email.trim(),
        },
      };

      let saved = this.$store.dispatch('createUser', data);

      saved.then(() => {
        this.loading = false;
        showToastSuccess("User saved success", this.$toast);
      }).catch(error => {
        this.loading = false;
        showToastError(error, this.$toast)
      });

    },
  },
};
</script>

<style lang="scss">
.row.row-inside {
  max-width: none;
}
</style>
